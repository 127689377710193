import {graphql} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import {Layout, MetaData, Hero} from '../components'
import {
  Section,
  BoxedContainer,
  TitleUppercase,
  SectionHeaderCenterAligned,
  Subtitle,
} from '../elements'
import {ExternalLinkPrimary, ButtonPrimary} from '../elements/Buttons'
import {
  Input,
  Label,
  FieldWrapper,
  Fieldset,
  Select,
  Textarea,
  Checkbox,
} from '../elements/Form'
import theme from '../styles/theme'

import heroIllustration from '../images/hire-us/expertise-cloud.svg'
import GhostIcon from '../images/inline-svgs/ghost-new-icon.svg'
import GatsbyIcon from '../images/inline-svgs/gatsby.svg'

const HireUsPage = ({data}) => {
  return (
    <Layout>
      <MetaData data={data.currentPage} />
      <Hero.Hero bg={theme.color.background.light}>
        <Hero.ContentColumn centerAlign>
          <Hero.Title>Hire us to build fast, professional websites.</Hero.Title>
          <Hero.Subtitle margin="22px 0 0">
            We develop beautifully coded <strong>custom Ghost themes</strong>{' '}
            and <strong>Gatsby sites</strong> on time and on budget.
          </Hero.Subtitle>
          <Hero.CTAs>
            <ExternalLinkPrimary href="#contact" round>
              Get a Free Quote
            </ExternalLinkPrimary>
          </Hero.CTAs>
        </Hero.ContentColumn>
        <Hero.ImageColumn>
          <img
            src={heroIllustration}
            alt=""
            style={{
              maxHeight: '600px',
            }}
          />
        </Hero.ImageColumn>
      </Hero.Hero>

      <Section backgroundColor={theme.color.primary.base} padding="fixed">
        <BoxedContainer medium>
          <TitleUppercase
            center
            large
            color={theme.color.primary.lightest}
            style={{
              margin: '0 0 60px',
            }}
          >
            Our Services
          </TitleUppercase>
          <Services>
            <Service>
              <ServiceIcon>
                <GhostIcon />
              </ServiceIcon>
              <ServiceTitle>Custom Ghost Theme Development</ServiceTitle>
              <ServiceDescription>
                With over 5 years of experience building sites with Ghost, we
                can build a high-quality, bug-free website for your business or
                personal use with Ghost CMS.
              </ServiceDescription>
            </Service>
            <Service>
              <ServiceIcon>
                <GatsbyIcon />
              </ServiceIcon>
              <ServiceTitle>Gatsby &amp; Jamstack Web Development</ServiceTitle>
              <ServiceDescription>
                We have experience building Gatsby sites with Ghost, Contentful,
                Netlify CMS, Prismic and Wordpress. Reach us and we can help you
                figure out the best stack of you.
              </ServiceDescription>
            </Service>
          </Services>
        </BoxedContainer>
      </Section>

      <Section
        backgroundColor={theme.color.background.light}
        padding="fixed"
        id="contact"
      >
        <BoxedContainer narrow>
          <SectionHeaderCenterAligned>
            <h2>Get a Free Quote of Your Project</h2>
            <Subtitle>
              Fill in the form and tell as about your needs or email us at{' '}
              <strong>
                <a
                  href="mailto:support@stylesheets.dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@stylesheets.dev
                </a>
              </strong>
              . We’ll get in touch with you.
            </Subtitle>
          </SectionHeaderCenterAligned>
          <Form name="consultancy" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="consultancy" />
            <Fieldset columns={2}>
              <FieldWrapper>
                <Label htmlFor="name">Full name</Label>
                <Input type="text" name="name" id="name" />
              </FieldWrapper>
              <FieldWrapper>
                <Label htmlFor="email">Email *</Label>
                <Input type="email" name="email" id="email" required />
              </FieldWrapper>
            </Fieldset>
            <Fieldset>
              <FieldWrapper>
                <Label>How can we support you?</Label>
                <Checkbox.Group>
                  <Checkbox.Checkbox
                    label="Custom Ghost Theme"
                    name="requirements[]"
                    value="Custom Ghost theme"
                    id="requirements-custom-theme"
                  />
                  <Checkbox.Checkbox
                    label="Ghost Theme Customization"
                    name="requirements[]"
                    value="Ghost Theme customization"
                    id="requirements-ghost-theme-customization"
                  />
                  <Checkbox.Checkbox
                    label="Gatsby Site Development"
                    name="requirements[]"
                    value="Gatsby site development"
                    id="requirements-gatsby-site"
                  />
                  <Checkbox.Checkbox
                    label="UI Design"
                    name="requirements[]"
                    value="UI Design"
                    id="requirements-ui-design"
                  />
                </Checkbox.Group>
              </FieldWrapper>
            </Fieldset>
            <Fieldset>
              <FieldWrapper>
                <Label htmlFor="timeline">Timeline</Label>
                <Select id="timeline" name="timeline">
                  <option value="none">Select one...</option>
                  <option value="one month">1 month</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                  <option value="1 year">1 year</option>
                  <option value="ongoing">Ongoing</option>
                </Select>
              </FieldWrapper>
            </Fieldset>
            {/* <Fieldset>
              <FieldWrapper>
                <Label htmlFor="budget">Budget</Label>
                <Select id="budget" name="budget">
                  <option value="none">Select one...</option>
                  <option value="less than 1000"> &lt; $1000</option>
                  <option value="1000 - 5000">$1000 - $5000</option>
                  <option value="5000 - 10000">$5000 - $10000</option>
                  <option value="10000 - 15000">$10000 - $15000</option>
                  <option value="15000 - 20000">$15000 - $20000</option>
                  <option value="more than 20000">$20000+</option>
                </Select>
              </FieldWrapper>
            </Fieldset> */}
            <Fieldset>
              <FieldWrapper>
                <Label htmlFor="description">Project description *</Label>
                <Textarea
                  id="description"
                  name="description"
                  placeholder="Tell us a bit about your project"
                  required
                />
              </FieldWrapper>
            </Fieldset>
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </Form>
        </BoxedContainer>
      </Section>
    </Layout>
  )
}

export default HireUsPage

export const query = graphql`
  {
    currentPage: ghostPage(slug: {eq: "hire-us"}) {
      ...GhostMetaPageFields
    }
  }
`

const Services = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 0 20px;
  }
`

const Service = styled.div`
  text-align: center;

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    text-align: left;
  }
`

const ServiceIcon = styled.div`
  width: 60px;
  margin: 0 auto 10px;

  svg {
    fill: #fff;
  }

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    margin: 0 0 10px;
  }
`

const ServiceTitle = styled.h3`
  color: ${p => p.theme.color.inverted};
  margin: 0 auto 30px;
  position: relative;
  padding: 0 0 30px;
  max-width: 300px;
  font-size: 2.8rem;

  &::after {
    content: ' ';
    border: 1px solid #fff;
    width: 70px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 35px);
  }

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    margin: 0 0 30px;

    &::after {
      left: 0;
    }
  }
`

const ServiceDescription = styled.p`
  color: ${p => p.theme.color.primary.lightest};
`

const Form = styled.form`
  background: ${p => p.theme.color.inverted};
  box-shadow: 0 2px 9px 0 #ede9f6;
  border-radius: 8px;
  padding: 40px 25px;
`
